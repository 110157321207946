import Footer from "../../../components/layout/footer/Footer";
import Header from "../../../components/layout/supplementary/header/Header";
import "./bookPage.css";
import defaultBookCover from "../../../assets/images/defaultBookCover.png";
import { useEffect, useState } from "react";
import {
  useGetChapterContent,
  useGetNovel,
  useGetNovelChapters,
} from "../../../redux/actions/bookActions";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Loading from "../../../components/splash/loading/Loading";
import FloatingNav from "../../../helper/novels/floating-nav/FloatingNav";

function BookPage() {
  const { id } = useParams();
  const getNovel = useGetNovel();
  const getNovelChapters = useGetNovelChapters();
  const getChapterContent = useGetChapterContent();
  const [loading, setLoading] = useState(false);
  const [novel, setNovel] = useState({});
  const [novelChapters, setNovelChapters] = useState([]);
  const [currentChapter, setCurrentChapter] = useState(1);
  const [totalChapters, setTotalChapters] = useState(1);
  const [chapterContent, setChapterContent] = useState({});

  const handleGetNovel = async () => {
    try {
      setLoading(true);
      const response = await getNovel(id);

      if (response.payload) {
        setNovel(response.payload);
      } else {
        console.log(response.message || "Failed to fetch novel");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleGetNovelChapters = async () => {
    try {
      setLoading(true);
      const response = await getNovelChapters(id);
      console.log("novel chapters", response);

      if (response.payload) {
        setNovelChapters(response.payload.results);
        setTotalChapters(response.payload.results.length);
      } else {
        console.log(response.message || "Failed to fetch novel chapters");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGetChapterContent = async () => {
    try {
      setLoading(true);
      const response = await getChapterContent({
        novelId: id,
        chapterId: currentChapter,
      });
      if (response.payload) {
        setChapterContent(response.payload);
      } else {
        console.log(response.message || "Failed to fetch chapter");
      }
    } catch (error) {
      console.log(error.response?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleNextChapter = () => {
    setCurrentChapter((prev) => prev + 1);
  };

  const handlePreviousChapter = () => {
    setCurrentChapter((prev) => prev - 1);
  };

  const handleChapterChange = (index) => {
    setCurrentChapter(index);
  };

  useEffect(() => {
    if (id) {
      handleGetNovel();
      handleGetNovelChapters();
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleGetChapterContent();
  }, [currentChapter]);

  if (!novelChapters)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100svh",
        }}
      >
        <ClipLoader color="#669933" size={40} />
      </div>
    );

  return (
    <div className="bp__bg">
      <div className="bp">
        <Header />
        <div className="bp__section__one">
          {currentChapter == 1 && (
            <img
              alt="book cover"
              src={novel?.cover_image ? novel.cover_image : defaultBookCover}
            />
          )}
          <h1>{novel?.title}</h1>
          {novel?.author && currentChapter == 1 && (
            <h3>{`Author: ${novel?.author?.name}`}</h3>
          )}
        </div>
        {loading ? (
          <div style={{ minHeight: "100vh", background: "white" }}>
            <Loading />
          </div>
        ) : (
          <div className="bp__section__two">
            <h1 className="bp__section__two__chapter">
              {`Chapter ${parseFloat(chapterContent.chapter)}: ${
                chapterContent.title || "Untitled chapter"
              }` || "Untitled Chapter"}
            </h1>
            {/* <span className="bp__section__two__info">
              <p>Word Count: 917</p>
              <p>Released: 12-08-2023</p>
            </span> */}
            <div
              className="bp__section__two__text"
              dangerouslySetInnerHTML={{ __html: chapterContent.content }}
            ></div>
            <span>
              {currentChapter > 1 && (
                <button onClick={handlePreviousChapter}>
                  PREVIOUS CHAPTER
                </button>
              )}
              {currentChapter < totalChapters && (
                <button onClick={handleNextChapter}>NEXT CHAPTER</button>
              )}
            </span>

            {/* Floating Navigation for Chapters */}
            <FloatingNav
              currentChapter={currentChapter}
              novelChapters={novelChapters}
              handlePreviousChapter={handlePreviousChapter}
              handleNextChapter={handleNextChapter}
              onChapterChange={handleChapterChange}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default BookPage;
